import {request} from 'redux/middleware/api';
import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {API} from 'config/businesspartner.config';
import {saveItem} from 'redux/localStorage';

export class Services {
  constructor() {}

  //createUpdateRecord
  createUpdateRecord = (token, endpoint, data, method) => {
    return request({
      endpoint,
      method,
      body: JSON.stringify(data),
      headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    })
      .then(res => res)
      .catch(err => err);
  };

  // delete records
  deleteRecord = (token, endpoint) => {
    return request({
      endpoint,
      headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
      method: 'DELETE',
    })
      .then(res => res)
      .catch(err => err);
  };

  //statusChange
  updateStatus = (token, endpoint, bodyData) => {
    return request({
      endpoint,
      body: JSON.stringify(bodyData),
      headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
      method: 'PATCH',
    })
      .then(res => res)
      .catch(err => err);
  };

  // uploading single file
  uploadFile = (token, endpoint, filedata, key) => {
    const formData = new FormData();
    formData.append(key, filedata);

    return fetch(endpoint, {
      method: 'PATCH',
      headers: JWT_HEADER(token),
      body: formData,
    })
      .then(res => res.json())
      .then(res => res)
      .catch(err => err);
  };

  // uploading multiple files in a single go
  uploadMultiFile = (token, endpoint, bodyData) => {
    const formData = new FormData();
    bodyData.map(({key, fileData}) => formData.append(key, fileData));
    return fetch(endpoint, {
      method: 'PATCH',
      headers: JWT_HEADER(token),
      body: formData,
    })
      .then(res => res.json())
      .then(res => res)
      .catch(err => err);
  };

  /// send form data ----  used to send binary data along with json
  sendFormdata = (token, endpoint, method, bodyData) => {
    const formData = new FormData();
    bodyData.map(({key, data, isFile}) => formData.append(key, data));
    return fetch(endpoint, {
      method: method,
      headers: JWT_HEADER(token),
      body: formData,
    })
      .then(res => res.json())
      .then(res => res)
      .catch(err => err);
  };

  fetchRecords = (endpoint, token, fetchAsBlob) => {
    const config = {
      method: 'GET',
      mode: 'cors',
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
      cache: 'default',
    };
    return fetch(endpoint, config)
      .then(response => (fetchAsBlob ? response.blob() : response.json()))
      .then(res => res)
      .catch(err => err);
  };

  sendAudienceMsg = (token, endpoint, bodyData) => {
    const config = {
      method: 'POST',
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
      body: JSON.stringify(bodyData),
    };
    return fetch(endpoint, config)
      .then(response => response.json())
      .then(res => res)
      .catch(err => err);
  };

  getProfileDocDownloadLink = (user_id, attachment_id) => {
    return fetch(
      `${
        API.administration.student_profile
      }${user_id}/attachment/${attachment_id}`,
      {
        method: 'GET',
      }
    ).then(res => res.json());
  };

  fetchChildInstitutions = (token, institution_type, endpoint) => {
    endpoint = `${endpoint}?child_institutions=True&institution_type=${institution_type}`;
    const config = {
      endpoint,
      method: 'GET',
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
    };
    return request(config)
      .then(res => res)
      .catch(err => err);
  };

  removeChildInstitution = (token, endpoint, data) => {
    const config = {
      endpoint,
      method: 'POST',
      body: JSON.stringify(data),
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
    };
    return request(config);
  };

  resendInvitationToUser = (endpoint, token, email) => {
    let body = JSON.stringify(email);
    if (typeof email === 'string') {
      body = JSON.stringify({email});
    }
    const config = {
      body,
      method: 'POST',
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
    };
    return fetch(endpoint, config);
  };

  updateUserStatus = (token, endpoint, data, method) => {
    return request({
      endpoint,
      method,
      body: JSON.stringify(data),
      headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    })
      .then(res => res)
      .catch(err => err);
  };

  checkInstituteUser = (token, endpoint, email) => {
    const config = {
      method: 'POST',
      body: JSON.stringify({email}),
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
    };
    return fetch(endpoint, config);
  };

  fetchDescendantsInstitution = (token, endpoint, institute_id) => {
    endpoint = `${endpoint}?action=get_descendants&institute_id=${institute_id}`;
    const config = {
      endpoint,
      method: 'GET',
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
    };
    return request(config)
      .then(res => res)
      .catch(err => err);
  };

  saveInstituteUsers = (token, endpoint, data, method) => {
    return request({
      endpoint,
      method,
      body: JSON.stringify(data),
      headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    })
      .then(res => res)
      .catch(err => err);
  };

  saveInstituteUsersAsFormData = (token, endpoint, data, method) => {
    const headers = Object.assign({}, JWT_HEADER(token), JSON_HEADER);
    return request({
      endpoint,
      method,
      body: data,
      headers: {
        Authorization: headers['Authorization'],
      },
    })
      .then(res => res)
      .catch(err => err);
  };

  checkFieldInputAvailability = (token, endpoint, fieldData) => {
    const config = {
      method: 'POST',
      body: JSON.stringify(fieldData),
      headers: token
        ? Object.assign({}, JWT_HEADER(token), JSON_HEADER)
        : Object.assign({}, JSON_HEADER),
    };
    return fetch(endpoint, config);
  };
}

export class ProfileServices extends Services {
  constructor(token, bodyToken, loggedInUserId, history, user_portal) {
    // this.services = new Services();
    super();
    this.token = token;
    this.bodyToken = bodyToken;
    this.loggedInUserId = loggedInUserId;
    this.history = history;
    this.user_portal = user_portal;
  }

  uploadProfileImage = data => {
    if (data.profile_image) {
      const endpoint = `${API.administration.picture}${this.loggedInUserId}/`;
      const bodyData = [
        {
          key: 'picture',
          fileData: data.profile_image,
        },
      ];
      return this.uploadMultiFile(this.token, endpoint, bodyData)
        .then(res => res)
        .catch(err => err);
    }
  };

  changePassword = data => {
    if (data.newpassword) {
      const endpoint = `${API.administration.changePassword}${
        this.loggedInUserId
      }/`;
      const bodyData = {
        new_password: data.newpassword,
        old_password: data.currentpassword,
        token: this.bodyToken,
      };
      const method = 'PUT';

      return this.createUpdateRecord(this.token, endpoint, bodyData, method)
        .then(res => {
          if (res.success) {
            saveItem('password', data.newpassword);
            return res;
          }
          return res;
        })
        .catch(err => err);
    }
  };

  sendMessage = (recipients, uri, dataObj = {}, recipientsKey = 'uuid') => {
    if (
      !recipientsKey === 'all' &&
      recipients &&
      Object.keys(recipients).length === 0
    ) {
      return false;
    }
    let data = {
      data: {
        message_type: 'EMAIL',
        ...dataObj,
      },
    };
    if (dataObj.currentPage) {
      data = {
        ...dataObj,
      };
    }
    this.history.push('/send/message', {
      recipients,
      token: this.token,
      uri,
      recipientsKey: recipientsKey,
      ...data,
      user_portal: this.user_portal,
    });
  };
}

export class ReporterServices {
  formatDescendentInstitutionData = descendentInstitutionArray => {
    const obj = {};
    descendentInstitutionArray.forEach(institution => {
      const {institution_type_name: {institution_type_id} = {}} = institution;
      if (institution_type_id) {
        if (obj[institution_type_id]) {
          obj[institution_type_id].push(institution);
        } else {
          obj[institution_type_id] = [institution];
        }
      }
    });
    return obj;
  };

  createDescendentListHeader = (institution_type_id, data) => {
    let descendentListHeaderData = [];
    // const {data: formData} = this.state;
    // const {instituteTypesData: {data} = {}} = this.props;
    data &&
      data.length > 0 &&
      data.forEach((type, index) => {
        let d = [];
        type &&
          type.sub_types &&
          type.sub_types.length &&
          type.sub_types.every(subType => {
            if (subType.institution_type_id === institution_type_id) {
              return false;
            } else {
              d.push(subType);
              return true;
            }
          });
        if (index === 0) {
          d = d.reverse();
        }
        descendentListHeaderData = descendentListHeaderData.concat(d);
      });
    return descendentListHeaderData.reverse();
  };
}
