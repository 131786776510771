import * as TYPES from './constants';
import {API} from 'config/employer.config';
import {JWT_HEADER, JSON_HEADER} from 'config/srm.config';
import {
  receiveTalentExchangeStudents,
  requestTalentExchangeStudents,
  handleTalentExchangeStudentsError,
} from './actions';

const initialState = {
  data: null,
  error: null,
  request: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.TALENT_EXCHANGE_STUDENTS_LIST_REQUEST:
      return {
        ...state,
        request: true,
      };
    case TYPES.TALENT_EXCHANGE_STUDENTS_LIST_RECEIVE:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.TALENT_EXCHANGE_STUDENTS_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.TALENT_EXCHANGE_STUDENTS_LIST_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const fetchTalentExchStudentsList = (params, filters = {}) => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access = {},
      access: {token = null},
    },
    instance: {
      data: {institution_id},
    },
  } = getState();
  const cognitoID = access['cognito:username'];

  let queries = '';
  const config = {
    mode: 'cors',
    method: 'GET',
    cache: 'default',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  dispatch(requestTalentExchangeStudents());
  let getFilterKeys = [];
  if (filters) {
    getFilterKeys = Object.keys(filters) || [];
  }
  const {page = 1, sort = 'date_desc', pageSize = 50} = params || {};
  // filters logic
  // remove this sort logic after the api values are changed to date_desc & date_asc
  let sortValue = sort;
  if (getFilterKeys.length > 0) {
    getFilterKeys.forEach(key => {
      if (Array.isArray(filters[key]) && filters[key].length > 0) {
        filters[key].forEach(item => {
          if (item) {
            queries += `&${key}=${item}`;
          }
        });
      } else if (!Array.isArray(filters[key])) {
        if (key !== 'years_of_exp_start' && key !== 'years_of_exp_end') {
          if (filters[key]) {
            queries += `&${key}=${filters[key]}`;
          }
        }
      }
    });

    if (filters['years_of_exp_end'] > 0) {
      if (filters['years_of_exp_start'] !== undefined) {
        queries += `&years_of_exp_start=${filters['years_of_exp_start']}`;
      }
      queries += `&years_of_exp_end=${filters['years_of_exp_end']}`;
    }
  }
  const URI = `${
    API.administration.talent_exchange
  }?page=${page}&page_size=${pageSize}&sort=${sortValue}${queries}&srm_user_uuid=${cognitoID}&institution_uuid=${institution_id}`;
  try {
    return fetch(URI, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveTalentExchangeStudents(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleTalentExchangeStudentsError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleTalentExchangeStudentsError(err));
  }
};
