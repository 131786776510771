// theme
export const CHANGE_APP_THEME_MODE = 'CHANGE_APP_THEME_MODE';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';

// License Types
export const REQUEST_SUPER_USER_LICENSE_TYPES =
  'REQUEST_SUPER_USER_LICENSE_TYPES';
export const RECEIVE_SUPER_USER_LICENSE_TYPES =
  'RECEIVE_SUPER_USER_LICENSE_TYPES';
export const SUPER_USER_LICENSE_TYPES_ERROR = 'SUPER_USER_LICENSE_TYPES_ERROR';

// SEND MESSAGE

export const SEND_MESSAGE_OPEN = 'SEND_MESSAGE_OPEN';
export const SEND_MESSAGE_CLOSE = 'SEND_MESSAGE_CLOSE';

// User Types
export const REQUEST_USER_TYPES = 'REQUEST_USER_TYPES';
export const RECEIVE_USER_TYPES = 'RECEIVE_USER_TYPES';
export const USER_TYPES_ERROR = 'USER_TYPES_ERROR';
