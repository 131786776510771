import {Grid} from 'mui-core';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/Error';
// import NoDataIcon from 'mui-core/Icons/NoDataIcon';
import {alpha, useTheme} from '@mui/material/styles';
import {Box, Typography, CircularProgress, Backdrop} from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const RequestErrorLoader = ({
  children,
  body = {},
  minHeight,
  hideEmpty,
  fullScreen,
  hideEmptyIcon,
  hideBackground, // UI is removed from dom while loading
  title = 'Loading...',
  overrideNoDataContainer,
  emptyMsg = 'No Results Found',
  errorMsg = 'Something went wrong.',
}) => {
  const theme = useTheme();
  const {request, data, error} = body;
  let fullScreenStyles = {};
  if (fullScreen && body.request) {
    fullScreenStyles = {
      top: 0,
      left: 0,
      zIndex: 1020,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      position: 'fixed',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.background.paper, 0.8),
    };
  }

  const LoadingScreen = () => (
    <Backdrop
      open
      sx={{
        color: 'white',
        backgroundColor: '#ffffff00',
        zIndex: theme.zIndex.drawer + 1,
      }}>
      <CircularProgress size={30} />
      <Typography variant="subtitle1" ml={2} color="text.primary">
        {title}
      </Typography>
    </Backdrop>
  );

  const EmptyScreen = () =>
    overrideNoDataContainer ? (
      overrideNoDataContainer
    ) : (
      <Grid
        xs
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{position: 'relative', width: '100%', minHeight: '300px'}}>
        {!hideEmptyIcon && <DescriptionOutlinedIcon sx={{fontSize: 64}} />}
        <Typography>{emptyMsg}</Typography>
      </Grid>
    );

  const ErrorScreen = () => (
    <Grid
      xs
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      sx={{position: 'relative', width: '100%'}}>
      {!hideEmptyIcon && (
        <ErrorIcon sx={{color: theme.palette.error.main, mb: 1}} />
      )}
      <Typography>{errorMsg}</Typography>
    </Grid>
  );

  const ChildrenScreen = () => (
    <Box
      sx={{
        width: '100%',
        transition: '2s all',
        opacity: request ? (!hideBackground ? 0.25 : 0) : 1,
      }}>
      {children}
    </Box>
  );

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display={request ? 'flex' : 'block'}
      sx={{
        position: 'relative',
        minHeight: (request && minHeight) || 'auto',
        ...fullScreenStyles,
      }}>
      {error ? (
        <ErrorScreen />
      ) : !hideBackground ? (
        <>
          {request && <LoadingScreen />}
          {!request &&
            data &&
            Array.isArray(data) &&
            data.length === 0 &&
            !hideEmpty && <EmptyScreen />}
          <ChildrenScreen />
        </>
      ) : (
        <>
          {request && <LoadingScreen />}
          {!request &&
          data &&
          Array.isArray(data) &&
          data.length === 0 &&
          !hideEmpty ? (
            <EmptyScreen />
          ) : (
            <ChildrenScreen />
          )}
        </>
      )}
    </Box>
  );
};

RequestErrorLoader.propTypes = {
  body: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
  hideEmpty: PropTypes.bool,
  fullScreen: PropTypes.bool,
  emptyMsg: PropTypes.string,
  errorMsg: PropTypes.string,
  minHeight: PropTypes.number,
  hideEmptyIcon: PropTypes.bool,
  hideBackground: PropTypes.bool,
  overrideNoDataContainer: PropTypes.element,
};

export default RequestErrorLoader;
