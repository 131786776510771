import {
  requestUserTypes,
  receiveUserTypes,
  handleUserTypesError,
} from './actions';
import * as TYPES from './constants';
import {Services} from 'core';
import {API} from 'config/srm.config';

const initialState = {
  data: null,
  error: null,
  request: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_USER_TYPES:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_USER_TYPES:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.USER_TYPES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchUserTypes = () => (dispatch, getState) => {
  const services = new Services();
  const {
    auth: {
      access: {token},
    },
  } = getState();
  dispatch(requestUserTypes());
  let endpoint = `${API.users.user_type}`;
  try {
    return services
      .fetchRecords(endpoint, token)
      .then(res => {
        dispatch(receiveUserTypes(res));
      })
      .catch(err => console.log(err));
  } catch (err) {
    console.log(err);
    dispatch(handleUserTypesError(err));
  }
};
