import {Services} from 'core';
import * as TYPES from './constants';
import {API} from 'config/srm.config';
import {requestResumeList, receiveResumeList, resumeListError} from './actions';

const initialState = {
  data: [],
  error: null,
  request: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_RESUME_LIST:
      return {...state, request: true, error: false};

    case TYPES.RECEIVE_STUDENT_RESUME_LISTS:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };

    case TYPES.STUDENT_RESUME_LIST_ERROR:
      return {...state, error: true, request: false};

    case TYPES.CLEAR_STUDENT_RESUME_LIST:
      return {...initialState};
    default:
      return state;
  }
};

export const fetchResumeList = (userName = '') => (dispatch, getState) => {
  dispatch(requestResumeList());
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    let endPoint = userName
      ? `${API.student.student_resume_list}?username=${userName}`
      : `${API.student.student_resume_list}`;
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) {
        dispatch(receiveResumeList(res));
      } else dispatch(resumeListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(resumeListError(err));
  }
};

export const downloadResume = (resumeId, userId, onSuccess, onError) => async (
  dispatch,
  getState
) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();
    const endPoint = `${
      API.student.student_resume_list
    }/${resumeId}?action=download&username=${userId}`;
    const response = await services.fetchRecords(endPoint, token, true);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError();
  }
};
