import {EMPLOYER_ROUTES} from './routes';
import {
  Settings,
  Insights,
  EventNote,
  Engineering,
  WorkOutline,
  FilterDrama,
  PersonSearch,
  AccountCircle,
  BusinessCenter,
  SupervisedUserCircleRounded,
} from '@mui/icons-material';

const SRM = process.env.REACT_APP_SRM;
const GPS = process.env.REACT_APP_GPS;
const STUDENT_PROFILE = process.env.REACT_APP_STUDENT_PROFILE;

export const API = {
  administration: {
    business_partner_program_student_list: `${SRM}business_partner/program/sign-up`,
    picture: `${SRM}user/picture/`,
    partner: `${SRM}partner/`,
    jobs: `${SRM}business-partner/jobs/`,
    programs: `${SRM}business-partner/program/`,
    changePassword: `${SRM}user/profile/`,
    message: `${SRM}partner-message/`,
    job_message: `${SRM}business_partner/jobs/send_message/`,
    program_message: `${SRM}business_partner/program_send_message/`,
    opportunity_message: `${SRM}business_partner/opportunity_send_message/`,
    //dashboard
    dashboard: `${SRM}srm_metrics/`,
    //industry: `${SRM}industry-places-cluster/`,
    industry: `${SRM}industry-sectors/`,
    student_profiles: `${GPS}v1/get-published-profiles/`,
    student_profile: `${STUDENT_PROFILE}user/user-profile/`,
    student_pathDetails: `${GPS}myprofile/`,
    student_exchange_programs: `${SRM}gps/program/`,
    student_exchange_data: `${GPS}v2/srm/student_exchange/`,
    // employer apis
    // employer details
    institution: `${SRM}v1/super-user/institute/`,
    // dashboard
    dashboard: `${SRM}srm_metrics/`,
    // user details
    employer_user: `${SRM}user/`,
    // jobs
    skills: `${SRM}gps/skills`,
    publish: `${SRM}publish-v1/`,
    education_level: `${SRM}business-partner/opportunity_education/`,
    experience_level: `${SRM}business-partner/opportunity_exp_level/`,
    // apprenticeships
    occupation: `${SRM}gps/occupation/`,
    // talent exchange
    talent_exchange: `${GPS}v2/srm/student_exchange/`,
    saved_searches: `${GPS}v2/srm/saved_searches`,
    saved_profiles: `${GPS}v2/srm/student_saved_profile/`,
    // emerging talent
    saved_career: `${GPS}v2/saved_career/`,
    emerging_talent: `${SRM}emerging_talent`,
    enrolled_program_details: `${SRM}gps/pathways-v2`,
    program_enrollments: `${SRM}emerging_talent/?program_enrollments`,
    gosurvey_results: `${GPS}v2/student_cis_data/?gosurvey_results=true`,
    enrolled_student_list: `${GPS}v2/srm/student_list_enrolled_for_path/`,

    // opportunities
    opportunities: `${SRM}business-partner/opportunity/`,
    // opportunity applicants
    business_partner_opportunity_student_list: `${GPS}v2/bp_opportunity_apply`,
    // settings filters
    skills_from_text: `${SRM}skills_from_text/`,
    location_details: `${SRM}gps/location_details`,
    acheivement_options: `${SRM}gps/acheivement-options/`,
    // settings
    career_one_stop_companies_list: `${SRM}career_one_stop_company_list/`,
  },
};

export const programTypes = [
  {
    icon: 'icon-apprenticeship',
    name: 'Apprenticeships',
  },
  {
    icon: 'icon-internship',
    name: 'Internships',
  },
];

export const PageStart = 1;
export const jobsPageSize = 10;
export const defautHomePage = '/';
export const DefaultPageSize = 12;
export const noOfAdminCardInPage = 12;
export const defaultJobSort = 'date_desc';

export const jobsSorters = [
  {
    key: 'asc',
    value: 'A to Z',
  },
  {
    key: 'desc',
    value: 'Z to A',
  },
];

export const eventAlert = {
  'Same Time': '0',
  '5 Minutes Before': '-300',
  '10 Minutes Before': '-600',
  '15 Minutes Before': '-900',
  '30 Minutes Before': '-1800',
  '1 Hours Before': '-3600',
  '2 Hours Before': '-7200',
  '1 Day Before': '-86400',
  '2 Day Before': '-172800',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const notificationSettingsData = [
  {
    title: 'New Event',
    data: [
      {
        label: 'From Community',
        key: 'alert_from_community',
      },
    ],
  },
  {
    title: 'Event Alerts',
    data: [
      {
        label: 'From Me',
        key: 'alert_from_me',
      },
      {
        label: 'From Community',
        key: 'new_from_community',
      },
    ],
  },
  {
    title: 'Event Responses',
    data: [
      {
        label: 'Survey Responses',
        key: 'response_from_survey',
      },
    ],
  },
];

export const profileMenu = [
  {
    key: 'my-profile',
    to: 'profile/edit',
    label: 'My Profile',
  },
];

export const homePageMenu = [
  {
    title: 'Home',
    imgSrc: 'icon-Home icon',
    key: 'Home',
    mainMenu: true,
    path: EMPLOYER_ROUTES.HOME.DASHBOARD,
  },
  {
    title: 'Administration',
    imgSrc: 'icon-Profile icon',
    key: 'Administration',
    content: [
      {
        submenuTitle: 'User Management',
        path: EMPLOYER_ROUTES.ADMIN.USER_MANAGEMENT,
      },
    ],
  },
  // {
  //   title: 'Programs',
  //   imgSrc: 'icon-programs-new icon',
  //   key: 'Programs',
  //   mainMenu: true,
  //   path: EMPLOYER_ROUTES.PROGRAMS.PROGRAMS_LIST,
  // },
  // {
  //   title: 'Jobs',
  //   imgSrc: 'icon-jobs icon',
  //   key: 'Jobs',
  //   mainMenu: true,
  //   path: EMPLOYER_ROUTES.JOBS.JOBS_LIST,
  // },
  {
    title: 'Opportunities',
    imgSrc: 'icon-programs-new icon',
    key: 'Opportunities',
    mainMenu: true,
    path: EMPLOYER_ROUTES.OPPORTUNITIES.OPPORTUNITIES_LIST,
  },
  {
    title: 'Events',
    imgSrc: 'icon-events icon',
    key: 'Events',
    mainMenu: true,
    path: EMPLOYER_ROUTES.EVENTS.EVENTS_LIST,
  },
  {
    title: 'Talent Exchange',
    imgSrc: 'icon-bpworkforceicon icon',
    key: 'Workforce',
    mainMenu: true,
    path: EMPLOYER_ROUTES.WORKFORCE.STUDENT_EXCHANGE,
  },
];

export const sideBarButtonMenu = [
  {
    key: 'job',
    title: 'Job',
    Icon: BusinessCenter,
    path: EMPLOYER_ROUTES.JOBS.CREATE_JOB,
  },
  {
    Icon: Engineering,
    key: 'apprenticeship',
    title: 'Apprenticeship',
    path: EMPLOYER_ROUTES.APPRENTICESHIPS.CREATE_APPRENTICESHIP,
  },
];

export const oldSideBarMenu = [
  {
    key: 'Home',
    Icon: Insights,
    title: 'Dashboard',
    path: EMPLOYER_ROUTES.HOME.OLD_DASHBOARD,
  },
  {
    Icon: WorkOutline,
    key: 'Opportunities ',
    title: 'Opportunities',
    path: EMPLOYER_ROUTES.OPPORTUNITIES.OPPORTUNITIES_LIST,
  },
  {
    key: 'Workforce',
    Icon: PersonSearch,
    title: 'Talent Exchange',
    path: EMPLOYER_ROUTES.WORKFORCE.STUDENT_EXCHANGE,
  },
  {
    key: 'Events',
    title: 'Events',
    Icon: EventNote,
    path: EMPLOYER_ROUTES.EVENTS.EVENTS_LIST,
  },
  {
    Icon: Settings,
    key: 'settings',
    title: 'Company Settings',
    path: EMPLOYER_ROUTES.EMPLOYER_PROFILE.CREATE_PROFILE,
  },
];
export const sideBarMenu = [
  {
    key: 'newHome',
    Icon: Insights,
    title: 'Dashboard',
    path: EMPLOYER_ROUTES.HOME.DASHBOARD,
  },
  {
    key: 'Jobs ',
    title: 'Jobs',
    Icon: BusinessCenter,
    path: EMPLOYER_ROUTES.JOBS.JOBS_LIST,
  },
  {
    Icon: Engineering,
    key: 'Apprenticeships',
    title: 'Apprenticeships',
    path: EMPLOYER_ROUTES.APPRENTICESHIPS.APPRENTICESHIPS_LIST,
  },
  {
    key: 'Talent Hub',
    Icon: FilterDrama,
    title: 'Talent Hub',
    path: EMPLOYER_ROUTES.TALENT_HUB.EMERGING_TALENT,
  },
  {
    Icon: AccountCircle,
    key: 'Administration',
    title: 'Administration',
    submenus: [
      {
        key: 'Home',
        title: 'User Management',
        Icon: SupervisedUserCircleRounded,
        path: EMPLOYER_ROUTES.ADMIN.USER_MANAGEMENT,
      },
    ],
  },
  {
    Icon: Settings,
    key: 'Settings',
    title: 'Company Settings',
    path: EMPLOYER_ROUTES.COMPANY_SETTINGS.SETTINGS,
  },
];
